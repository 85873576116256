<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        src="../../../public/backgrounds/services/fotovoltaic_bg.jpg"
        lazy-src="../../../public/backgrounds/services/fotovoltaic_bg-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-sluzby"
      >
      <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-row>
        </template>
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container class="ma-0 px-4 text-center">
            <p class="site-text__h3 white--text">Solárna energia</p>
            <p class="ml-1 italic" style="max-width: 800px">
              Využite energiu zo slnka a znížte si náklady za elektrickú energiu
              v domácnosti alebo vo firme.
            </p>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row
      :class="'ma-0 ' + $vuetify.breakpoint.xsOnly ? '' : 'my-8'"
      justify="center"
    >
      <ContentSection
        title="Fotovoltika pre domácnosť"
        img="fotovoltika/dom"
        id="FV-dom"
      >
        <template v-slot:text>
          Mať fotovoltickú elektráreň priamo doma, bolo v minulosti veľkou
          raritou a finančne náročnou záležitosťou. Dnes vieme, že
          elektropriemysel rýchlo napreduje, technológie prudko pokročili a
          preto sú fotovoltické elektrárne dostupné takmer pre každého. Vďaka
          dotácii stoja menej ako jazdené auto. <br />Navrhnutá elektráreň od
          nás Vám nie len splatí svoju cenu, ale počas svojej životnosti ušetrí
          možno aj na nové auto. Domáca fotovoltika je teda účinný spôsob, ako
          znížiť alebo eliminovať náklady na elektrickú energiu, a zároveň, ako
          zvýšiť svoju nezávislosť od elektrickej siete a ako šetriť našu
          planétu. Bonusom fotovoltickej elektrárne je aj skutočnosť, že
          automaticky zvyšuje hodnotu nehnuteľnosti, a z osobných skúseností
          vieme, že dokáže byť aj estetickým prvkom na vašej streche.
        </template>
      </ContentSection>
      <ContentSection
        title="Fotovoltika pre firmu"
        img="fotovoltika/firma"
        id="FV-firma"
        inverted
      >
        <template v-slot:text>
          Často sa stretávame s podnikateľmi a firmami, ktorí veľkú časť svojich
          ziskov z podnikania musia v dnešnej dobe vložiť do faktúr za energie.
          Ak chcú byť tieto subjekty konkurencieschopné a udržať si ceny a
          kvalitu, výhodným strategickým krokom je práve zníženie nákladov na
          energie. Investícia do fotovoltickej elektrárne u firiem je preto
          výhodná a návratnosť investície veľmi rýchla. <br />S našimi
          kvalitnými a spoľahlivými solárnymi panelmi a odborným technickým
          poradenstvom, môžu firmy efektívne využívať obnoviteľnú energiu. Vedie
          to k dlhodobému zníženiu prevádzkových nákladov a posilneniu
          ekologického, ale aj ekonomického profilu.
        </template>
      </ContentSection>
      <ContentSection
        title="Prístrešok pre auto"
        img="fotovoltika/carport"
        id="carport"
      >
        <template v-slot:text>
          Naše fotovoltické car-porty kombinujú funkciu parkoviska s výhodami
          fotovoltickej energie. Tieto inovatívne štruktúry ochraňujú vozidlá
          pred nepriaznivými poveternostnými podmienkami, a zároveň slúžia ako
          nabíjačky pre elektroautá. Pre parkovanie pod takýmto prístreškom
          dokonca nepotrebujete ani elektroauto, jednoducho Vám tento prístrešok
          napojíme na dom a energiu zo slnka spotrebujete v domácnosti.
        </template>
      </ContentSection>
      <ContentSection
        title="Skladovanie energie"
        img="fotovoltika/bateria"
        id="bateria"
        inverted
      >
        <template v-slot:text>
          Naše riešenia pre skladovanie energie umožňujú domácnostiam a firmám
          efektívne zhromažďovať a využívať nadbytočnú solárnu energiu v čase,
          keď je potrebná. Skladovanie elektriny zvyšuje efektívnosť FV systému,
          a zároveň znižuje našu závislosť od elektrickej energie zo siete. V
          prípade domácnosti je možnosť ukladať energiu v tzv. „virtuálnej
          batérii“, ktorú prevádzkujú distribučné spoločnosti. Tieto batérie vám
          uskladňujú prebytočnú energiu, ktorú je možné využívať počas
          nedostatku slnka alebo v noci. Virtuálnu batériu vám v prípade záujmu
          bližšie popíšeme a vybavíme všetky potrebné náležitosti za Vás.
        </template>
      </ContentSection>
    </v-row>
  </v-container>
</template>

<script>
import ContentSection from "./ContentSection.vue";

export default {
  name: "PageFotovltaic",
  components: { ContentSection },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          document.getElementById(refName).scrollIntoView(true);
        }, 100);
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
  color: var(--v-primary-darken2);
}
.highlight {
  color: var(--v-primary-darken2);
}
.italic {
  font-style: italic;
}
</style>