<template>
  <!-- <v-hover v-slot="{ hover }"> -->
  <v-card
    class="card"
    rounded="lg"
    elevation="8"
    :width="cardDimensionOptions.width"
    :ripple="false"
    @mouseover="handleHover('mouseover')"
    @mouseleave="handleHover('mouseleave')"
    @click="handleHover('cardClick')"
  >
    <v-img
      class="card-image"
      :src="require('../../public/images/home/' + section.image)"
      :width="cardDimensionOptions.width"
      :aspect-ratio="cardDimensionOptions.ratio"
    >
      <div class="card-overlay">
        <v-icon class="mb-n1">
          {{ hover ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
        <p :class="'card-title site-text__' + (mobileScreen ? 'h6' : 'h5')">
          {{ section.title }}
        </p>
        <v-expand-transition>
          <v-row v-if="hover" class="ma-0" justify="center">
            <v-chip
              v-for="([subTitle, hashPath, icon], i) in section.subSections"
              :key="i"
              class="card-chip mx-1"
              :to="`/sluzby/${section.path}${hashPath}`"
              color="black"
              outlined
            >
              <v-icon left size="23">{{ icon }}</v-icon>
              <span> {{ subTitle }} </span>
            </v-chip>
          </v-row>
        </v-expand-transition>
      </div>
    </v-img>
  </v-card>
  <!-- </v-hover> -->
</template>

<script>
export default {
  name: "HomePageServiceTile",
  props: {
    section: { type: Object, required: true },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    cardDimensionOptions() {
      const screenBreakpoint = this.$vuetify.breakpoint.name;
      switch (screenBreakpoint) {
        case "xs": {
          return { width: "50vw", ratio: 2 / 3 };
        }
        case "sm": {
          if (this.$vuetify.breakpoint.width > 750) {
            return { width: "50vw", ratio: 6 / 4 };
          } else {
            return { width: "50vw", ratio: 1 };
          }
        }
        case "lg": {
          if (this.$vuetify.breakpoint.width > 1500) {
            return { width: "50vw", ratio: 6 / 4 };
          } else {
            return { width: "50vw", ratio: 1 };
          }
        }
        default: {
          return { width: "30vw", ratio: 6 / 4 };
        }
      }
    },
  },
  methods: {
    handleHover(eventType) {
      switch (eventType) {
        case "mouseover":
          if (!this.mobileScreen) {
            this.hover = true;
          }
          break;
        case "mouseleave":
          this.hover = false;
          break;
        case "cardClick":
          if (this.mobileScreen) {
            this.hover = !this.hover;
          } else {
            this.$router.push({ path: `/sluzby/${this.section.path}` });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
}
.card-image {
  align-items: end;
}
.card-overlay {
  padding: 4px;
  padding-block-end: 8px;
  text-align: center;
  background-color: rgba(white, 0.9);
}
.card-title {
  margin-block-end: 4px;
  text-align: center;
}
.card-chip {
  margin-block-start: 4px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 160px;
}
</style>