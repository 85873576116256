<template>
  <v-card
    :id="projectData.folder"
    :ref="projectData.folder"
    class="pa-4 my-4"
    max-width="100%"
    rounded="lg"
    outlined
  >
    <p class="site-text__h5 primary--text text-center">
      {{ projectData.title }}
    </p>
    <p class="site-text">
      {{ projectData.description }}
    </p>
    <!-- Photos -->
    <v-row class="ma-0" justify="center">
      <VImage
        v-for="number in numOfDisplayedImages.max"
        :key="number"
        :src="getImageUrl(number)"
        @img-click="openImage(number)"
      />
      <!-- More photos btn -->
      <VImage
        v-if="numOfDisplayedImages.isMore"
        :src="getImageUrl(numOfDisplayedImages.max + 1)"
        @img-click="openImage(numOfDisplayedImages.max + 1)"
        overlay
      >
        <template slot="img-overlay">
          <v-icon>mdi-image-multiple-outline</v-icon>
          +{{ this.projectData.numOfPhotos - numOfDisplayedImages.max }}
        </template>
      </VImage>
    </v-row>
  </v-card>
</template>

<script>
import VImage from "./VImage.vue";

export default {
  name: "ProjectsPageCard",
  components: { VImage },
  props: {
    projectData: { type: Object, required: true },
  },
  computed: {
    numOfDisplayedImages() {
      let maxLimit;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          if (this.$vuetify.breakpoint.width <= 894) {
            maxLimit = 4;
          } else {
            maxLimit = 6;
          }
          break;
        case "md":
          if (this.$vuetify.breakpoint.width <= 1138) {
            maxLimit = 6;
          } else {
            maxLimit = 8;
          }
          break;
        case "lg":
          if (this.$vuetify.breakpoint.width <= 1627) {
            maxLimit = 6;
          } else {
            maxLimit = 8;
          }
          break;
        case "xl":
          if (this.$vuetify.breakpoint.width <= 1992) {
            maxLimit = 8;
          } else {
            maxLimit = 10;
          }
          break;
      }
      if (this.projectData.numOfPhotos <= maxLimit) {
        return { max: this.projectData.numOfPhotos, isMore: false };
      } else {
        return { max: maxLimit - 1, isMore: true };
      }
    },
  },
  methods: {
    getImageUrl(imageNumber) {
      return require("../../public/projects/" +
        this.projectData.folder +
        imageNumber +
        ".jpg");
    },
    openImage(imgIndex) {
      this.$emit("open-image", this.projectData, imgIndex);
    },
  },
};
</script>