<template>
  <v-app>
    <!-- App bar -->
    <TheAppBar />
    <!-- Main content -->
    <v-main>
      <router-view :class="$vuetify.breakpoint.xsOnly ? '' : 'mb-12'">
      </router-view>
    </v-main>
    <!-- Footer -->
    <TheFooter />
    <!-- Scroll up button -->
    <v-scale-transition origin="center">
      <v-btn
        class="mr-4 mb-4"
        style="z-index: 1"
        v-scroll="onScroll"
        @click="toTop"
        v-show="fab"
        color="primary"
        elevation="12"
        width="50"
        height="50"
        fab
        fixed
        bottom
        right
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </v-app>
</template>

<script>
// FEAT ANIMATIONS (mostly home screen)
import TheAppBar from "./components/TheAppBar.vue";
import TheFooter from "./components/TheFooter.vue";
export default {
  name: "App",
  components: { TheAppBar, TheFooter },
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
#app {
  font-family: "Open Sans", sans-serif;
  background-color: var(--v-background-base);
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bold {
  font-weight: bold;
}
.bolder {
  font-weight: 300;
}
.italic {
  font-style: italic;
}

.site-text,
.site-text__h3,
.site-text__h4,
.site-text__h5,
.site-text__h6 {
  @extend .unselectable;
  color: var(--v-text-base);
}
.site-text__h3 {
  font-size: 2.7rem;
}
.site-text__h4 {
  font-size: 2.125rem;
}
.site-text__h5 {
  font-size: 1.6rem;
}
.site-text__h6 {
  font-size: 1.125rem;
  @extend .bold;
}
</style>
