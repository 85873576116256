<template>
  <v-row
    class="my-8 ma-0"
    :style="'max-width: ' + ($vuetify.breakpoint.smAndDown ? '100vw' : '70vw')"
    align="center"
  >
    <v-row class="ma-0 my-5">
      <!-- Text -->
      <v-col cols="12" lg="6" :class="'text-col ' + textOrder">
        <p class="site-text__h4">{{ title }}</p>
        <slot name="text"></slot>
      </v-col>
      <!-- Image -->
      <v-col cols="12" lg="6" class="d-flex align-center justify-center">
        <v-img
          :src="require('../../../public/images/' + img + '.svg')"
          :lazy-src="require('../../../public/images/' + img + '.png')"
          width="100%"
          :max-width="$vuetify.breakpoint.xsOnly ? 350 : 500"
          transition="fade-transition"
          contain
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "ContentSection",
  props: {
    title: { type: String, required: true },
    img: { type: String, required: true },
    inverted: { type: Boolean, required: false, default: false },
  },
  computed: {
    textOrder() {
      return this.inverted && !this.$vuetify.breakpoint.mdAndDown
        ? "order-last"
        : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.text-col {
  align-self: center;
}
</style>