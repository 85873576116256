<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        src="../../../public/backgrounds/services/recuperation_bg.jpg"
        lazy-src="../../../public/backgrounds/services/recuperation_bg-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-sluzby"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-row>
        </template>
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container class="ma-0 px-4 text-center">
            <p class="site-text__h3 white--text">Rekuperácia</p>
            <p class="ml-1 italic" style="max-width: 800px">
              Vetrajte domácnosť šetrne cez systém rekuperácie a zároveň si
              udržiavajte čistý vzduch v dome.
            </p>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row
      :class="'ma-0 ' + $vuetify.breakpoint.xsOnly ? '' : 'my-8'"
      justify="center"
    >
      <ContentSection
        title="Centrálna rekuperácia"
        img="rekuperacia/central_rekuperacia"
        id="centralna-rekuperacia"
      >
        <template v-slot:text>
          Je systém zariadení a potrubných rozvodov s jednou centrálnou
          jednotkou rekuperácie pre celý objekt. Systém rekuperácie pracuje na
          pomerne jednoduchom princípe. V zime teplý vzduch z vnútra ohreje
          chladný vonkajší vzduch cez výmenník tepla, čiže nestrácate energiu
          ktorú ste vložili do kúrenia, ale zároveň máte čerstvý vzduch z vonku.
          V lete to funguje naopak. Studený vzduch vo vnútri domu schladzuje
          vonkajší vzduch. Okrem výhody úspory elektrickej energie systém
          priaznivo vplýva na zdravie ľudí v dome. Je preto vhodný pre
          alergikov, ktorí majú vďaka nemu stále čistý vzduch, filtrovaný cez
          uhlíkové filtre, čím sa eliminuje prítomnosť prírodných alergénov v
          dome.
        </template>
      </ContentSection>
      <ContentSection
        title="Lokálna rekuperácia"
        img="rekuperacia/lokal_rekuperacia"
        id="lokalna-rekuperacia"
        inverted
      >
        <template v-slot:text>
          Je malá jednotka, ktorá sa umiestňuje na obvodové múry jednotlivých
          izieb. Táto jednotka vymieňa vzduch len v jednej miestnosti, nie je
          určená pre celý objekt. Je vhodná do hotových budov, kde nie je možný
          veľký stavebný zásah a ťahanie potrubí.
        </template>
      </ContentSection>
    </v-row>
  </v-container>
</template>

<script>
import ContentSection from "./ContentSection.vue";
export default {
  name: "PageRecuperation",
  components: { ContentSection },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          document.getElementById(refName).scrollIntoView(true);
        }, 100);
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  },
};
</script>