<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="ma-4 d-flex justify-center"
      :width="isMobile ? '40vw' : '15vw'"
      :height="isMobile ? 250 : 250"
      :elevation="hover ? 12 : 4"
      rounded="lg"
    >
      <v-row class="ma-0 flex-column">
        <v-row class="ma-0 mx-4 text-center flex-column" justify="center">
          <v-icon class="mb-4" size="55" color="primary">{{ icon }}</v-icon>
          <p
            class="site-text__h6 primary--text"
            :style="isMobile ? 'font-size: 0.9rem !important' : ''"
          >
            {{ title }}
          </p>
          <slot class="site-text" name="text"></slot>
        </v-row>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "ContactsPageTile",
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>