<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        src="../../../public/backgrounds/services/project_bg.jpg"
        lazy-src="../../../public/backgrounds/services/project_bg-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-sluzby"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-row>
        </template>
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container class="ma-0 px-4 text-center">
            <p class="site-text__h3 white--text">Projekčná činnosť</p>
            <p class="ml-1 italic" style="max-width: 800px">
              Objednajte si u nás projekt elektrickej inštalácie, fotovoltiky či
              elektrickej prípojky.
            </p>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row
      :class="'ma-0 ' + $vuetify.breakpoint.xsOnly ? '' : 'my-8'"
      justify="center"
    >
      <!-- Elek. instalacia -->
      <ContentSection
        title="Projektová dokumentácia"
        img="projekt/projekt-elektro"
        id="projekt-elektro"
      >
        <template v-slot:text>
          Pred realizáciou elektrickej inštalácie je vhodná príprava projektovej
          dokumentácie. Stretávame sa však zvyčajne s tým, že skupina
          montážnikov umiestňuje káble v objekte bez vopred vypracovaného
          projektu, a to len na základe ich skúseností či odhadov. Takto
          realizovaná montáž, môže viesť k chybne navrhnutej elektrickej
          inštalácii, nevyváženej záťaži na jednotlivé fázy, či k preťaženiu
          jednotlivých vedení z dôvodu zlej dimenzie káblov. <br />Projektová
          dokumentácia pozostáva štandardne zo situačného projektu, jednopólovej
          schémy rozvádzačov a technickej správy, ktorá popisuje realizáciu
          projektu.
        </template>
      </ContentSection>
      <!-- Pripojka domu-->
      <ContentSection
        title="Projekt elek. prípojky"
        img="projekt/projekt-pripojky"
        id="projekt-pripojky"
        inverted
      >
        <template v-slot:text>
          Pri vybavovaní pripojenia odberného miesta budete potrebovať okrem
          iných náležitostí, aj projekt elektrickej prípojky. Vieme Vám pomôcť
          nie len s projektom, ale zároveň aj s celým postupom prihlasovania a
          vybavovania. Vykonávame aj realizácie a preto si u nás viete vybaviť
          „prípojku na kľuč“ bez vypĺňania nekonečných dokumentov u
          distribučných spoločností.
        </template>
      </ContentSection>
      <!-- FV -->
      <ContentSection
        title="Projekt fotovoltiky"
        img="projekt/projekt-fv"
        id="projekt-FV"
      >
        <template v-slot:text>
          Zriaďovať FV elektráreň bez riadnej projektovej prípravy môže viesť k
          viacerým porúcham a nebezpečným situáciám. Nepodceňte preto projektovú
          prípravu a dajte si u nás vypracovať projekt FV elektrárne. Okrem
          toho, že projekt slúži na správny návrh elektrárne, nezaobídete sa bez
          neho ani pri následne vykonávanej revízii. <br />Okrem projektovej
          prípravy Vám vieme zabezpečiť aj realizáciu od samotného začiatku až
          po bod, kedy si v smartfóne budete prezerať aktuálnu výrobu cez
          aplikáciu. Všetko vyriešime za Vás a bez vašich starostí.
        </template>
      </ContentSection>
    </v-row>
  </v-container>
</template>

<script>
import ContentSection from "./ContentSection.vue";

export default {
  name: "PageProject",
  components: { ContentSection },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          document.getElementById(refName).scrollIntoView(true);
        }, 100);
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  },
};
</script>