import Vue from 'vue'
import App from './App.vue'

// dependencies
import VueClipboard from 'vue-clipboard2'
// plugins
import vuetify from './plugins/vuetify';
import router from './plugins/vue-router'
import {index} from './plugins/store'

Vue.config.productionTip = false

Vue.use(VueClipboard)

new Vue({
  vuetify,
  router,
  store: index,
  render: h => h(App),
}).$mount('#app')
