<template>
  <v-app-bar height="80" color="transparent" absolute>
    <v-container
      class="pa-0 d-inline-flex align-center"
      :style="'max-width: ' + (mobileScreen ? '100vw' : '70vw')"
    >
      <!-- Logo -->
      <router-link to="/">
        <VLogoHorizontal color="white" />
      </router-link>
      <v-spacer></v-spacer>
      <!-- Desktop menu -->
      <v-list
        class="pa-0 d-inline-flex transparent"
        v-if="$vuetify.breakpoint.mdAndUp"
        flat
      >
        <template v-for="(item, index) in navItems">
          <!-- normal nav item -->
          <v-list-item
            v-if="item.path"
            :key="index"
            :to="item.path"
            class="white--text"
            active-class="secondary--text"
          >
            <v-list-item-content>
              <v-list-item-title class="pa-0 d-inline-flex align-center">
                <v-card-text class="ma-0 pa-0">
                  {{ item.text }}
                </v-card-text>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- sub-menu nav item -->
          <v-menu v-else :key="index" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :class="subNavMenuColor + '--text '"
              >
                <v-list-item-content>
                  <v-list-item-title class="pa-0 d-inline-flex align-center">
                    <v-card-text class="ma-0 pa-0">
                      {{ item.text }}
                      <v-icon class="mb-1" :color="subNavMenuColor" small>
                        mdi-chevron-down
                      </v-icon>
                    </v-card-text>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list class="pa-0" color="rgba(255,255,255,0.9)">
              <v-list-item-group v-model="activeSubNavTab">
                <v-list-item
                  v-for="(subItem, subIndex) in item.submenu"
                  :key="subIndex"
                  :to="subItem.path"
                  active-class="site-text white--text primary"
                  class="site-text black--text"
                >
                  <v-list-item-content>
                    <v-list-item-title class="pa-0 d-inline-flex align-center">
                      <v-icon color="black" size="20">
                        mdi-{{ subItem.icon }}
                      </v-icon>
                      <p class="ma-0 ml-4" style="font-size: 0.9rem">
                        {{ subItem.text }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-list>
      <!-- Mobile menu -->
      <v-menu
        v-model="mobileMenuOpened"
        v-if="$vuetify.breakpoint.smAndDown"
        transition="scroll-y-transition"
        :close-on-content-click="false"
        min-width="210"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" text large>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <template v-for="(item, index) in navItems">
            <!-- normal nav item -->
            <v-list-item
              v-if="item.path"
              :key="index"
              :to="item.path"
              active-class="site-text white--text primary"
              class="site-text black--text"
            >
              <v-list-item-content>
                <v-list-item-title class="pa-0 d-inline-flex align-center">
                  <v-icon color="black"> mdi-{{ item.icon }} </v-icon>
                  <p class="ma-0 ml-4">
                    {{ item.text }}
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- sub-menu nav item -->
            <v-list-group
              v-else
              :key="index"
              active-class="site-text black--text"
              class="site-text black--text"
            >
              <template v-slot:activator>
                <v-list-item-title class="pa-0 d-inline-flex align-center">
                  <v-icon color="black"> mdi-{{ item.icon }} </v-icon>
                  <p class="ma-0 ml-4">
                    {{ item.text }}
                  </p>
                </v-list-item-title>
              </template>
              <v-divider></v-divider>
              <v-list-item
                v-for="(subItem, subIndex) in item.submenu"
                :key="subIndex"
                :to="subItem.path"
                active-class="site-text white--text primary"
                class="site-text black--text"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="pa-0 pl-3 d-inline-flex align-center"
                  >
                    <v-icon color="black" size="20">
                      mdi-{{ subItem.icon }}
                    </v-icon>
                    <p class="ma-0 ml-4" style="font-size: 0.8rem">
                      {{ subItem.text }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list-group>
          </template>
        </v-list>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import VLogoHorizontal from "./VLogoHorizontal.vue";

export default {
  name: "TheAppBar",
  components: { VLogoHorizontal },
  data() {
    return {
      mobileMenuOpened: false,
      activeSubNavTab: undefined,
      navItems: [
        { text: "DOMOV", path: "/", icon: "home-outline" },
        {
          text: "SLUŽBY",
          icon: "account-hard-hat",
          submenu: [
            {
              text: "FOTOVOLTIKA",
              path: "/sluzby/fotovoltika",
              icon: "solar-power-variant-outline",
            },
            {
              text: "BLESKOZVOD",
              path: "/sluzby/bleskozvod",
              icon: "weather-lightning",
            },
            {
              text: "PROJEKČNÁ ČINNOSŤ",
              path: "/sluzby/projekt",
              icon: "floor-plan",
            },
            {
              text: "ELEKTROINŠTALÁCIA",
              path: "/sluzby/elektroinstalacia",
              icon: "home-lightning-bolt-outline",
            },
            {
              text: "REKUPERÁCIA",
              path: "/sluzby/rekuperacia",
              icon: "air-filter",
            },
            {
              text: "ELEK. KÚRENIE",
              path: "/sluzby/kurenie",
              icon: "heating-coil",
            },
          ],
        },
        { text: "PROJEKTY", path: "/projekty", icon: "android-studio" },
        {
          text: "KONTAKT",
          path: "/kontakt",
          icon: "phone-message-outline",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.mobileMenuOpened = false;
    },
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    subNavMenuColor() {
      return this.activeSubNavTab >= 0 ? "secondary" : "white";
    },
  },
};
</script>