<template>
  <svg
    id="a"
    :viewBox="'0 0 ' + viewWidth + ' ' + viewHeight"
    version="1.1"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs17">
      <linearGradient
        id="b"
        x1="264.35001"
        y1="5963.1001"
        x2="2670.3101"
        y2="5963.1001"
        gradientTransform="rotate(0.24,1146.4293,2523.3953)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".13" stop-color="#02455f" id="stop4" />
        <stop offset=".22" stop-color="#015973" id="stop6" />
        <stop offset=".41" stop-color="#007f98" id="stop8" />
        <stop offset=".59" stop-color="#009ab2" id="stop10" />
        <stop offset=".75" stop-color="#00abc3" id="stop12" />
        <stop offset=".87" stop-color="#00b1c9" id="stop14" />
      </linearGradient>
    </defs>
    <g
      id="g45"
      transform="matrix(0.12618721,0,0,0.12618721,-30.132894,-696.17551)"
    >
      <path
        :style="'fill: ' + color"
        d="m 1659.04,6348.06 h -66.57 l -9.98,47.7 h 75.53 l -3.29,8.44 h -83.53 l 24.15,-114.21 h 79.56 l -1.82,8.44 H 1603 l -8.71,41.19 h 66.57 z"
        id="path19"
      />
      <path
        :style="'fill: ' + color"
        d="m 1675.65,6404.2 25.59,-120.92 h 8.83 l -25.59,120.92 z"
        id="path21"
      />
      <path
        :style="'fill: ' + color"
        d="m 1722.11,6366.01 c -0.16,1.1 -0.31,2.38 -0.31,3.96 0,19.3 10.59,28.38 26.04,28.38 13.51,0 21.74,-4.6 28.54,-15.67 h 9.33 c -6.38,12.98 -16.22,23.75 -39.01,23.75 -21.26,0 -33.88,-13.52 -33.88,-35.88 0,-25.23 16.93,-48.66 45.24,-48.66 23.45,0 33.41,15.53 33.41,33.48 0,3.39 -0.32,6.61 -1.14,10.64 h -68.2 z m 60.37,-7.85 c 0,-0.16 0.16,-1.59 0.16,-3.48 0,-12.4 -6.38,-24.73 -25.87,-24.73 -16.69,0 -29.2,10.32 -33.48,28.21 h 59.2 z"
        id="path23"
      />
      <path
        :style="'fill: ' + color"
        d="m 1879.93,6379.62 c -5.13,12.78 -15.33,26.82 -39.98,26.82 -24.65,0 -34.03,-16.22 -34.03,-35.54 0,-25.1 16.78,-49 44.45,-49 25.58,0 32.94,16.89 33.4,27.92 h -8.69 c -1.26,-9.68 -8.37,-19.85 -25.69,-19.85 -21.78,0 -34.32,19.92 -34.32,40.29 0,16.05 8.24,28.1 26.32,28.1 15.54,0 23.81,-7.76 29.53,-18.74 h 9 z"
        id="path25"
      />
      <path
        :style="'fill: ' + color"
        d="m 1897.02,6324.14 h 16.12 l 5.75,-27.31 h 8.84 l -5.75,27.31 h 21.22 l -1.81,7.75 h -21.22 l -10.5,49.93 c -2.22,10.48 -0.34,15.1 8.91,15.1 4.12,0 8.44,-0.47 10.84,-1.11 l -1.65,7.58 c -3.38,1.28 -7.74,1.61 -12.09,1.61 -14.2,0 -18.07,-8.02 -15.02,-22.61 l 10.67,-50.49 h -16.12 l 1.81,-7.75 z"
        id="path27"
      />
      <path
        :style="'fill: ' + color"
        d="m 1954.85,6347.67 c 1.6,-7.85 3.37,-16.33 4.65,-23.54 h 8.34 c -0.16,2.1 -1.91,11.5 -3.81,19.76 7.99,-14.53 20.56,-21.36 33.71,-21.36 l -2.16,9.45 c -19.62,0.46 -31.92,15.5 -36.42,36.87 l -7.48,35.35 h -8.84 l 12.01,-56.52 z"
        id="path29"
      />
      <path
        :style="'fill: ' + color"
        d="m 2014.53,6324.14 h 8.83 l -16.97,80.06 h -8.83 z m 8.62,-40.86 h 8.84 l -3.54,16.89 h -8.84 z"
        id="path31"
      />
      <path
        :style="'fill: ' + color"
        d="m 2108.56,6379.62 c -5.13,12.78 -15.33,26.82 -39.98,26.82 -24.65,0 -34.03,-16.22 -34.03,-35.54 0,-25.1 16.78,-49 44.45,-49 25.58,0 32.94,16.89 33.4,27.92 h -8.69 c -1.26,-9.68 -8.37,-19.85 -25.69,-19.85 -21.78,0 -34.32,19.92 -34.32,40.29 0,16.05 8.24,28.1 26.32,28.1 15.54,0 23.8,-7.76 29.53,-18.74 h 9 z"
        id="path33"
      />
      <path
        :style="'fill: ' + color"
        d="m 2194.52,6289.99 h 9.48 l -10.53,49.61 h 74.35 l 10.53,-49.61 h 9.48 l -24.16,114.21 h -9.48 l 11.96,-56.16 h -74.35 l -11.96,56.16 h -9.48 l 24.15,-114.21 z"
        id="path35"
      />
      <path
        :style="'fill: ' + color"
        d="m 2373.08,6359.35 c 0,23.39 -13.97,47.09 -44.33,47.09 -21.74,0 -35.64,-14.94 -35.64,-37.12 0,-23.91 16.05,-47.42 44.48,-47.42 24.21,0 35.49,18.5 35.49,37.45 z m -70.97,9.47 c 0,17.94 11.56,29.53 27.76,29.53 24.16,0 34.19,-20.05 34.19,-38.39 0,-16.51 -9.04,-29.98 -27.77,-29.98 -21,0 -34.18,18.62 -34.18,38.84 z"
        id="path37"
      />
      <path
        :style="'fill: ' + color"
        d="m 2454.97,6382.74 c -1.44,7.2 -3.04,14.74 -4.16,21.46 h -8.83 c 0.16,-2.55 1.42,-10.61 2.85,-16.33 -6.06,10.15 -13.23,18.57 -32.38,18.57 -17.75,0 -28.49,-10.87 -23.53,-33.92 l 10.26,-48.38 h 8.99 l -9.92,46.39 c -2.72,12.95 -2.06,27.65 15.85,27.65 19.1,0 31.14,-12.78 37.16,-40.54 l 7.21,-33.51 h 8.83 l -12.33,58.6 z"
        id="path39"
      />
      <path
        :style="'fill: ' + color"
        d="m 2484.34,6378.91 c 2.06,15.18 14.39,19.8 27.76,19.8 13.37,0 24.25,-6.15 24.25,-16.29 0,-8.09 -5.25,-12.99 -23.39,-16.97 -21.82,-4.82 -27.57,-10.95 -27.57,-21.03 0,-13.64 12.18,-22.51 30.87,-22.51 29.16,0 32.78,18.05 32.78,24.68 h -8.84 c -1.26,-7.31 -6.19,-16.81 -25.05,-16.81 -13.58,0 -20.59,5.67 -20.59,14.08 0,6.98 6.19,10.31 22.27,13.97 21.98,4.98 29.01,11.57 29.01,23.58 0,14.3 -12.69,25.04 -35.02,25.04 -20.99,0 -34.34,-9.27 -35.3,-27.53 h 8.83 z"
        id="path41"
      />
      <path
        :style="'fill: ' + color"
        d="m 2570.89,6366.01 c -0.16,1.1 -0.31,2.38 -0.31,3.96 0,19.3 10.59,28.38 26.04,28.38 13.51,0 21.74,-4.6 28.54,-15.67 h 9.33 c -6.38,12.98 -16.22,23.75 -39.01,23.75 -21.26,0 -33.88,-13.52 -33.88,-35.88 0,-25.23 16.93,-48.66 45.24,-48.66 23.45,0 33.41,15.53 33.41,33.48 0,3.39 -0.32,6.61 -1.14,10.64 h -68.2 z m 60.37,-7.85 c 0,-0.16 0.16,-1.59 0.16,-3.48 0,-12.4 -6.38,-24.73 -25.87,-24.73 -16.69,0 -29.2,10.32 -33.48,28.21 h 59.2 z"
        id="path43"
      />
    </g>
    <g
      id="g63"
      transform="matrix(0.12618721,0,0,0.12618721,-30.132894,-696.17551)"
    >
      <path
        :style="'fill: ' + color"
        d="m 1781.38,6236.6 c -22.73,5.32 -51.26,7.25 -85.11,7.25 h -90.91 l 78.82,-369.94 h 80.27 c 94.78,0 147.01,27.08 132.99,92.37 -5.32,25.63 -18.86,46.42 -41.1,62.38 -9.67,6.77 -20.79,12.57 -32.88,16.93 15.96,5.32 29.02,13.06 38.2,23.21 17.41,18.86 23.7,42.56 17.41,70.6 -4.84,24.18 -16.93,45.46 -35.3,63.35 -18.37,17.89 -39.17,28.53 -62.38,33.85 z m -56.1,-170.71 h -10.64 l -29.98,141.21 h 32.88 c 27.08,0 44.01,-3.38 56.58,-14.02 13.06,-11.12 23.21,-28.53 28.53,-55.13 5.8,-26.6 2.42,-44.01 -7.74,-54.64 -9.67,-10.64 -31.43,-17.41 -69.64,-17.41 z m 81.73,-150.88 c -7.25,-4.35 -21.76,-7.25 -46.91,-7.25 -3.39,0 -7.74,0.48 -12.09,0.48 l -26.11,124.28 h 1.93 c 36.27,0 59,-6.29 71.09,-14.99 11.61,-8.71 21.28,-23.7 26.11,-47.88 3.87,-16.93 3.87,-29.02 1.45,-36.75 -2.9,-7.74 -7.74,-13.54 -15.47,-17.89 z"
        id="path47"
      />
      <path
        :style="'fill: ' + color"
        d="m 2071.88,6242.1 c -47.24,0 -73.71,-14.66 -97.73,-43.17 -24.84,-29.32 -31.76,-67.6 -21.99,-114.84 9.77,-45.61 33.39,-83.48 70.86,-111.58 36.24,-27.69 68.41,-41.94 114.43,-41.94 46.02,0 73.3,13.85 98.14,41.13 26.06,28.51 33.39,66.79 23.21,114.02 -9.77,46.02 -32.58,83.89 -69.23,112.8 -35.84,28.91 -68.82,43.57 -117.69,43.57 z m 59.86,-283.02 c -30.54,0 -46.83,9.77 -68.01,30.95 -20.77,20.36 -36.24,52.12 -45.61,95.7 -9.37,44.39 -7.33,76.15 4.48,96.51 12.22,21.18 25.25,30.95 58.64,30.95 28.51,0 48.46,-10.18 70.04,-30.95 20.36,-19.96 31.76,-50.9 41.13,-96.11 9.37,-43.98 11.4,-74.52 0,-95.29 -12.22,-21.18 -28.91,-31.76 -60.68,-31.76 z"
        id="path49"
      />
      <path
        :style="'fill: ' + color"
        d="m 2286.04,6242.1 17.92,-84.7 30.54,-144.16 17.92,-82.67 h 60.27 l -60.27,283.02 h 161.67 l -6.11,28.51 z"
        id="path51"
      />
      <path
        :style="'fill: ' + color"
        d="m 2748.52,5958.67 h -39.09 l -41.54,196.69 -18.33,86.74 h -60.68 l 18.33,-86.74 41.94,-196.69 H 2527.8 l 6.92,-28.1 h 302.57 l -5.29,28.1 z"
        id="path53"
      />
      <path
        :style="'fill: ' + color"
        d="m 2806.44,6243.86 21.28,-100.58 36.27,-171.19 21.28,-98.17 h 71.57 l -71.57,336.09 h 191.98 l -7.25,33.85 h -263.55 z"
        id="path55"
      />
      <path
        :style="'fill: ' + color"
        d="m 3107.7,6242.1 17.92,-85.52 30.54,-144.16 17.51,-81.85 h 60.68 l -17.51,81.85 -30.54,144.16 -18.33,85.52 z"
        id="path57"
      />
      <path
        :style="'fill: ' + color"
        d="m 3463.98,6242.1 -138.05,-252.48 -36.65,171.85 -15.47,73.3 h -28.1 l 15.47,-73.3 32.58,-151.89 16.7,-79 h 58.23 l 109.14,204.02 30.54,-143.75 13.03,-60.27 h 30.13 l -13.44,63.53 -52.53,248 h -21.58 z"
        id="path59"
      />
      <path
        :style="'fill: ' + color"
        d="m 3710.26,5958.67 h -30.13 l -11.4,53.75 -2.04,8.55 -8.96,43.57 h 84.3 l -6.11,28.1 h -84.3 l -5.7,26.88 -1.63,6.52 -2.04,10.99 -4.48,20.36 -11.81,56.2 h 127.87 l -6.11,28.1 h -188.55 l 17.92,-84.3 4.48,-20.36 3.67,-17.51 22.8,-107.51 17.51,-81.85 h 189.36 l -5.7,28.51 h -98.96 z"
        id="path61"
      />
    </g>
    <polygon
      class="c"
      points="1517.28,6404.37 248.78,6276.35 1269.63,6084.94 1388.87,5524.51 2657.38,5652.53 1636.52,5843.94 "
      id="polygon65"
      style="fill: url(#b)"
      transform="matrix(0.12618721,0,0,0.12618721,-30.132894,-696.17551)"
    />
    <line
      class="d"
      x1="182.3891"
      y1="45.038067"
      x2="167.4687"
      y2="115.06063"
      id="line67"
      style="stroke-width: 0.126187"
    />
  </svg>
</template>

<script>
export default {
  name: "VLogoHorizontal",
  props: {
    color: { type: String, required: false, default: "black" },
  },
  computed: {
    width() {
      return this.$vuetify.breakpoint.xsOnly ? "210" : "270";
    },
    height() {
      return this.$vuetify.breakpoint.xsOnly ? "80" : "80";
    },
    viewWidth() {
      return this.$vuetify.breakpoint.xsOnly ? "452" : "452";
    },
    viewHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "110" : "110";
    },
  },
};
</script>

<style lang="scss" scoped>
.c {
  fill: url(#b);
}
.d {
  fill: none;
}
.e {
  fill: #010101;
}
</style>


