<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Header (image and title) -->
    <v-row class="ma-0">
      <v-img
        src="../../public/backgrounds/projects_background.jpg"
        lazy-src="../../public/backgrounds/projects_background-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-projekty"
      >
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container
            :class="'ma-0 ' + (mobileScreen ? 'px-4 text-center' : '')"
          >
            <p class="site-text__h3 white--text">Projekty</p>
            <p class="ml-1" style="max-width: 800px">
              Toto je prehľad všetkých projektov, ktoré sme realizovali. Na
              fotográfiach priamo zo stavieb si môžete pozrieť priebeh a
              výsledky našej práce.
            </p>
            <v-btn to="/" color="secondary" outlined> Domov </v-btn>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- Projects grid -->
    <v-row class="ma-0" justify="center">
      <v-card
        :width="$vuetify.breakpoint.mdAndDown ? '100vw' : '70vw'"
        color="background"
        flat
      >
        <v-row class="ma-0" justify="space-between">
          <v-col class="d-flex flex-column" v-for="col in colsCount" :key="col">
            <ProjectsPageCard
              v-for="(project, index) in getProjects(col)"
              :key="index"
              :projectData="project"
              @open-image="openImage"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <!-- Image opener -->
    <ProjectsPageImageOpener ref="img-opener" />
  </v-container>
</template>

<script>
import ProjectsPageCard from "./ProjectsPageCard.vue";
import ProjectsPageImageOpener from "./ProjectsPageImageOpener.vue";
// FEAT Pagination for projects on ProjectsPage
export default {
  name: "ProjectsPage",
  components: { ProjectsPageCard, ProjectsPageImageOpener },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    projects() {
      return this.$store.getters["projects/getProjects"];
    },
    dividedProjects() {
      let evenProjects = [];
      let oddProjects = [];
      this.projects.forEach((project, index) => {
        if (index % 2 == 0) {
          evenProjects.push(project);
        } else {
          oddProjects.push(project);
        }
      });
      return {
        even: evenProjects,
        odd: oddProjects,
      };
    },
    colsCount() {
      return this.$vuetify.breakpoint.xsOnly ? 1 : 2;
    },
  },
  methods: {
    scrollToAnchorPoint(elementId) {
      document.getElementById(elementId).scrollIntoView(true);
    },
    getProjects(colIndex) {
      if (this.colsCount === 1) {
        return this.projects;
      } else {
        if (colIndex === 1) {
          return this.dividedProjects.even;
        } else if (colIndex === 2) {
          return this.dividedProjects.odd;
        }
      }
    },
    openImage(projectData, imageNumber) {
      this.$refs["img-opener"].showImage(projectData, imageNumber);
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          this.scrollToAnchorPoint(refName);
        }, 100);
      } else {
        this.$vuetify.goTo(0);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.image {
  cursor: pointer;
  height: 120px;
  width: 180px;
  object-fit: cover;
}
.image-mobile {
  cursor: pointer;
  height: 60px;
  width: 90px;
  object-fit: cover;
}
.img-arrow-left {
  background-color: rgba(0, 0, 0, 0.27);
  border-radius: 100px 0 0 100px;
  cursor: pointer;
}
</style>