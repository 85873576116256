<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        src="../../../public/backgrounds/services/blesk_bg.jpg"
        lazy-src="../../../public/backgrounds/services/blesk_bg-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-sluzby"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-row>
        </template>
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container class="ma-0 px-4 text-center">
            <p class="site-text__h3 white--text">Bleskozvod</p>
            <p class="ml-1 italic" style="max-width: 800px">
              Chráňte sa pred zásahom blesku a jeho vplyvom na elektrické
              zariadenia, správne navrhnutým systémom ochrany pred bleskom.
            </p>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row
      :class="'ma-0 ' + $vuetify.breakpoint.xsOnly ? '' : 'my-8'"
      justify="center"
    >
      <ContentSection
        title="Bleskozvod"
        img="bleskozvod/bleskozvod"
        id="bleskozvod"
      >
        <template v-slot:text>
          Naše bleskozvody sa nenavrhujú odhadom montážnika, ani podľa
          zastaralých noriem a technických spôsobov. Náš projektant navrhuje
          bleskozvod podľa aktuálne platného súboru noriem STN EN 62305.
          Zakladáme si na bezpečnosti a spĺňame kritéria nových noriem. Pri
          montovaní bleskozvodu dbáme na ochranu objektu, minimalizujeme
          znečistenie strechy a vzniknutý odpad dôkladne odstránime, aby bol Váš
          dom po našom odchode rovnaký, ale zároveň oveľa bezpečnejší.
          <p class="mt-4 italic">
            V prípade že sa chete dozvedieť viac, odporúčame Vám tento odborný
            článok časpoísu Daibau.sk -
            <a
              class="highlight"
              href="https://www.daibau.sk/clanok/1184/odborne_instalovany_bleskozvod_chrani_objekt_pred_nasledkami_uderu_blesku"
            >
              Odborne inštalovaný bleskozvod chráni objekt pred následkami úderu
              blesku</a
            >
          </p>
        </template>
      </ContentSection>
      <ContentSection
        title="Izolovaný bleskozvod"
        img="bleskozvod/izolovany_bleskozvod"
        id="izolovany_bleskozvod"
        inverted
      >
        <template v-slot:text>
          Významnú úlohu pri vonkajšej ochrane pred bleskom zohráva bezpečná
          vzdialenosť. Dodržiavanie bezpečnej vzdialenosti nie je možné u
          všetkých systémov (napr. plochá strecha s veľkou hustotou uloženia FV
          panelov na malom priestore). V takomto prípade je možné použiť vysoko
          napäťový vodič, ktorý je špeciálne skonštruovaný na zvedenie
          bleskových prúdov. Takéto bleskozvody sú finančne náročnejšie, nakoľko
          použité vodiče sú pomerne novinkou a ich výroba je zložitejšia.
          <br />Obrovskou výhodou týchto vodičov je niekoľkonásobné vyššia
          bezpečnosť pred preskokom bleskového prúdu a pred elektromagnetickými
          vplyvmi.
        </template>
      </ContentSection>
    </v-row>
  </v-container>
</template>

<script>
import ContentSection from "./ContentSection.vue";
export default {
  name: "PageLightning",
  components: { ContentSection },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          document.getElementById(refName).scrollIntoView(true);
        }, 100);
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
</style>