<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        src="../../../public/backgrounds/services/electric_bg.jpg"
        lazy-src="../../../public/backgrounds/services/electric_bg-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-sluzby"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-row>
        </template>
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container class="ma-0 px-4 text-center">
            <p class="site-text__h3 white--text">Elektroinštalácia</p>
            <p class="ml-1 italic" style="max-width: 800px">
              Od obhliadky, prípravy projektu, ťahania káblov až po poslednú
              namontovanú zásuvku<br />... u nás máte všetko pod jednou
              strechou.
            </p>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row
      :class="'ma-0 ' + $vuetify.breakpoint.xsOnly ? '' : 'my-8'"
      justify="center"
    >
      <!-- Domova pripojka -->
      <ContentSection
        title="Pripojenie odberného miesta"
        img="elektroinstalacia/domova_pripojka"
        id="domova_pripojka"
      >
        <template v-slot:text>
          Elektrická prípojka je hlavnou tepnou elektrickej energie v dome či
          byte. Pri jej realizácii preto dávame veľký dôraz na ochranu kábla. V
          rámci prác zrealizujeme : osadenie elektromerového rozvádzača
          (zvyčajne na hranici pozemku, prístupnej z verejnej komunikácie),
          uloženie kábla od elektromera k hlavnému domovému rozvádzaču a
          zapojenie kábla. <br />Ak máte záujem zbaviť sa agendy súvisiacej so
          žiadosťou o pripojenie v distribučnej spoločnosti, vybavovanie
          revízie, podpisovanie dokumentov...pomôžeme Vám a všetky náležitosti
          vybavíme za Vás v rámci „prípojky na kľuč“.
        </template>
      </ContentSection>
      <!-- Elektricky rozvadzac -->
      <ContentSection
        title="Elektrický rozvádač"
        img="elektroinstalacia/elektricky_rozvadzac"
        id="elek_rozvadzac"
        inverted
      >
        <template v-slot:text>
          Každý elektrifikovaný objekt obsahuje aspoň jeden rozvádzač, v ktorom
          sa nachádzajú istiace a ochranné zariadenia. Okrem týchto zariadení
          môže rozvádzač obsahovať aj riadiace, spínacie, kontrolné alebo iné
          zariadenia. <br />Všetky naše rozvádzače sú dôkladne navrhnuté tak,
          aby bola zaistená maximálna bezpečnosť a súčasne čo najkompaktnejšie
          technické prevedenie. Každý rozvádzač je unikátny a preto je pri ich
          návrhu potrebný jedinečný prístup, čo je u nás samozrejmosťou.
        </template>
      </ContentSection>
      <!-- Elektricke rozvody -->
      <ContentSection
        title="Elektrické rozvody"
        img="elektroinstalacia/elektricke_rozvody"
        id="elek_rozvody"
      >
        <template v-slot:text>
          Elektrické rozvody zhotovujeme na mieru pre zákazníka. V praxi to
          znamená, že zákazník nám pri obhliadke popíše množstvo a typy
          zariadení, ktoré sa v objekte budú nachádzať. Na základe tejto
          požiadavky sa vytvorí projektová dokumentácia, kde sa záťaž rozdelí do
          viacerých špecifických obvodov. Tieto obvody sa následne ťahajú
          káblami v stene, pod podlahou alebo v strope. Elektrické rozvody sa
          preto ideálne realizujú po vymurovaní stien a pred zaliatím poteru či
          dokončením stropu.
          <br />Typy bežne používaných obvodov: zásuvkové a svetelné obvody,
          obvod pre chladničku, rúru, mikrovlnku, obvod pre žalúzie alebo
          termostaty a podobne.
        </template>
      </ContentSection>
      <!-- Datove rozvody -->
      <ContentSection
        title="Datové rozvody"
        img="elektroinstalacia/datove_rozvody"
        id="datove_rozvody"
        inverted
      >
        <template v-slot:text>
          V dnešnej dobe internetu je súčasťou každej domácnosti hneď niekoľko
          zariadení, ktoré si vyžadujú pripojenie na internet či televíznu sieť.
          V rámci zriadenia elektrickej inštalácie sa preto realizujú aj dátové
          rozvody. Zákazník nám ozrejmí, kde chce mať prístup ku káblovému
          internetu alebo TV, a my podľa jeho požiadavky zrealizujeme prípravu
          pre budúcu internetovú alebo TV zásuvku. Súčasťou dátových rozvodov je
          aj príprava káblov pre zariadenia ako: router, satelit, anténa, switch
          a podobne.<br /><br />Pre zákazníkov, ktorí myslia aj na bezpečnosť,
          býva neoddeliteľnou súčasťou týchto prác aj príprava infraštruktúry
          pre napojenie bezpečnostných kamier, nahravacieho zariadenia (tzv.
          NVR) a iných častí monitorovacieho systému.
        </template>
      </ContentSection>
      <!-- Elektricke zariadenia -->
      <ContentSection
        title="Elektrické zariadenia"
        img="elektroinstalacia/elektricke_zariadenia"
        id="elek_zariadenia"
      >
        <template v-slot:text>
          Zásuvky, vypínače, svetlá, LED pásy, dátové zásuvky, termostaty,
          ovládače... Všetko Vám skompletizujeme v rámci inštalácie zariadení.
          Zákazník si môže zvoliť, aké svetlá chce namontovať, zvoliť si štýl
          zásuviek a vypínačov a podobne. Inštalácia zariadení sa považuje za
          ukončenie elektrických prác v objekte. Po jej dokončení môžete naplno
          využívať elektrickú energiu vo všetkých spotrebičoch.
        </template>
      </ContentSection>
    </v-row>
  </v-container>
</template>

<script>
import ContentSection from "./ContentSection.vue";
export default {
  name: "PageElectric",
  components: { ContentSection },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          document.getElementById(refName).scrollIntoView(true);
        }, 100);
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
</style>