<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        lazy-src="../../public/backgrounds/contacts_background-lazy.jpg"
        src="../../public/backgrounds/contacts_background.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-kontakty"
      >
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container
            :class="'ma-0 ' + (mobileScreen ? 'px-4 text-center' : '')"
          >
            <p class="site-text__h3 white--text">Kontakt</p>
            <p class="ml-1" style="max-width: 800px">
              V prípade záujmu o naše služby, nás môžete kontaktovať na uvedenom
              telefónnom čísle alebo emailovej adrese. Okrem toho Nás môžete
              nájsť aj na Facebooku a Instagrame
            </p>
            <v-btn to="/" color="secondary" :small="mobileScreen" outlined>
              Domov
            </v-btn>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- Content -->
    <v-row class="mx-0 mt-12" justify="center">
      <v-row
        class="ma-0"
        :style="'max-width: ' + (mobileScreen ? '100vw' : '70vw')"
        justify="center"
      >
        <ContactsPageTile
          title="Pracovné hodiny"
          icon="mdi-clipboard-text-clock-outline"
        >
          <p
            slot="text"
            :style="mobileScreen ? 'font-size: 0.9rem !important' : ''"
          >
            Pondelok - Piatok<br />8:00-16:00
          </p>
        </ContactsPageTile>
        <ContactsPageTile title="Kancelária" icon="mdi-map-marker">
          <p
            slot="text"
            :style="mobileScreen ? 'font-size: 0.9rem !important' : ''"
          >
            Sabinov, <br />Pod švablovkou 26
          </p>
        </ContactsPageTile>
        <ContactsPageTile title="Telefonné číslo" icon="mdi-phone-in-talk">
          <p
            slot="text"
            :style="mobileScreen ? 'font-size: 0.9rem !important' : ''"
          >
            +421 917 380 165
          </p>
        </ContactsPageTile>
        <ContactsPageTile title="Email" icon="mdi-email-outline">
          <p
            slot="text"
            :style="mobileScreen ? 'font-size: 0.9rem !important' : ''"
          >
            info@boltline.sk
          </p>
        </ContactsPageTile>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import ContactsPageTile from "./ContactsPageTile.vue";

export default {
  name: "ContactsPage",
  components: { ContactsPageTile },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style>
</style>