const projects = {
  namespaced: true,
  state:{
    projects: [
      {
        title: "Rodinný dom v Lúčke",
        subtitle: "Kompletný fotovoltický system",
        folder: "Lucka_FV/",
        description:
        "Tentokrát sme sa ocitili v obci Lúčka, kde sme zriadili fotovoltiku pre novostavbu rodinného domu.",
        numOfPhotos: 4,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom v obci Fintice",
        subtitle: "Kompletný fotovoltický system",
        folder: "Fintice_FV/",
        description:
        "Vo Finticiach sme zákazníkovi zriadili kompletny fotovoltický systém na streche rodinného domu.",
        numOfPhotos: 4,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom v meste Sabinov",
        subtitle: "Kompletný fotovoltický system",
        folder: "Sabinov_FV/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne podľa požiadaviek nášho klienta.",
        numOfPhotos: 4,
        mainImgIndex: 4,
      },
      {
        title: "Rodinný dom v obci Dubovica",
        subtitle: "Návrh a realizácia bleskozvodu",
        folder: "Dubovica_Blesk/",
        description:
        "V Dubovici sme tentokrát pracovali na bleskozvode pre rodinný dom, ktorý prechádzal kompletnou rekonštrukciou.",
        numOfPhotos: 5,
        mainImgIndex: 1,
      },
      {
        title: "Rodinný dom v obci Drienica",
        subtitle: "Kompletný fotovoltický system",
        folder: "Drienica_FV/",
        description:
        "V obci Drienica sme zákazníkovi zriadili kompletny fotovoltický systém na streche rodinného domu.",
        numOfPhotos: 7,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom v obci Svinia",
        subtitle: "Rekuperácia",
        folder: "Svinia_Rekup/",
        description:
        "Tentokrát sme sa vrátili do obce Svinia k zákazníkovi, ktorému zabezpečujeme kompletné elektrické práce na novostavbe. V tejto fáze sme inštalovalí rekuperačný systém do stropnej časti domu.",
        numOfPhotos: 5,
        mainImgIndex: 5,
      },
      {
        title: "Rodinný dom v Župčanoch",
        subtitle: "Kompletný fotovoltický system",
        folder: "Zupcany_FV_3/",
        description:
        "V obci Župčany sme pomohli s ďalšou, v poradí už treťou fotovoltikou v tejto obci. Tentokrát však s malo zmenou, fotovoltiku sme inštalovali na plochu strechu.",
        numOfPhotos: 5,
        mainImgIndex: 4,
      },
      {
        title: "Rodinný dom v Župčanoch",
        subtitle: "Kompletný fotovoltický system",
        folder: "Zupcany_FV_2/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne podľa požiadaviek nášho klienta.",
        numOfPhotos: 4,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom Šalgovík",
        subtitle: "Kompletizácia elektrických zariadení",
        folder: "Salgovik_Kompletizacia/",
        description:
        "Kompletizácia rodinného domu v Šalgovíku, ktorá zahŕňala zapojenie koncových prvkov elek. inštalácie, montáž svietidiel a zapojenie spotrebičov.",
        numOfPhotos: 5,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom Cemjata",
        subtitle: "Kompletizácia elektrických zariadení",
        folder: "Cemjata_Kompletizacia/",
        description:
        "Kompletizácia rodinného domu v obci Cemjata, ktorá zahŕňala zapojenie koncových prvkov elek. inštalácie, montáž svietidiel a zapojenie spotrebičov.",
        numOfPhotos: 6,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom v obci Sedliská",
        subtitle: "Bleskozvod a uzemňovacia sústava",
        folder: "Sedliska_Blesk/",
        description:
        "Kompletné zriadenie bleskozvodu vrátane uzemnenia.",
        numOfPhotos: 5,
        mainImgIndex: 1,
      },
      {
        title: "Základy domu v obci Svinia",
        subtitle: "Uzemňovacia sústava bleskozvodu",
        folder: "Svinia_Blesk/",
        description:
        "Vytvorenie uzemňovacej sústavy v základoch rodinného domu.",
        numOfPhotos: 5,
        mainImgIndex: 2,
      },
      {
        title: "Rodinný dom v obci Fintice",
        subtitle: "Inštalácia infra fólií",
        folder: "Fintice_IF/",
        description:
        "Inštalácia infra fólií ako primárneho kúrenia v rodinom dome vo Finticiach.",
        numOfPhotos: 7,
        mainImgIndex: 3,
      },
      {
        title: "Rodinný dom v obci Nemcovce",
        subtitle: "Kompletný fotovoltický system",
        folder: "Nemcovce_FV/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne podľa požiadaviek nášho klienta.",
        numOfPhotos: 7,
        mainImgIndex: 3,
      },
      {
        title: "Rodinný dom v obci Šútovce",
        subtitle: "Návrh a realizácia bleskozvodu",
        folder: "Sutovce_Blesk/",
        description:
        "Zriadenie zachytávacej a zvodovej sústavy s pripojením na jestvujúci základový uzemňovač.",
        numOfPhotos: 8,
        mainImgIndex: 6,
      },
      {
        title: "Rodinný dom v Novej Ľubovni",
        subtitle: "Realizácia bleskozvodu a kopmletného FV systému",
        folder: "NovaLubovna_Blesk-FV/",
        description:
        "Realizácia bleskozvodu a fotovoltickej elektrárne v obci Nová Ľubovňa. Boli použité 2 rozdielne konštrukcie. Jedna na rovnú časť strechy so záťažovými prvkami pre vytvorenie potrebného uhla. "+
        "A druhá konštrukcia pre šikmu strechu sklon 45°.",
        numOfPhotos: 13,
        mainImgIndex: 11,
      },
      {
        title: "Rodinný dom v obci Bočiar pri Košiciach",
        subtitle: "Návrh a realizácia bleskozvodu",
        folder: "Bociar_Blesk/",
        description:
        "Zriadenie zachytávacej a zvodovej sústavy s pripojením na jestvujúci základový uzemňovač.",
        numOfPhotos: 7,
        mainImgIndex: 7,
      },
      {
        title: "Rodinný dom v obci Malá Ida",
        subtitle: "Realizácia bleskozvodu a kopmletného fotovoltického systému",
        folder: "MalaIda_FV/",
        description:
        "Realizácia fotovoltickej elektrárne v obci Malá Ida. Veľkosť fotovoltickej elektrárne bola navrhnutá na 6,30kwp. Nainštalovaná bola tiež Inteligentná trojfázová AC nabíjačka pre elektrické autá s výkonom 11kW",
        numOfPhotos: 7,
        mainImgIndex: 1,
      },
      {
        title: "Drevenica vo Veľkej Lomnici",
        subtitle: "Realizácia bleskozvodu a kopmletného fotovoltického systému",
        folder: "Lomnica_Blesk-FV/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne a bleskozvodu na drevenom kanadskom zrube vo Veľkej Lomnici.",
        numOfPhotos: 14,
        mainImgIndex: 4,
      },
      {
        title: "Rodinný dom Prešov (Šalgovík)",
        subtitle: "Realizácia elektroinštalácie a bleskozvodu",
        folder: "Salgovik_Elek-Blesk/",
        description:
        "V tomto rodinnom dome sme zabezpečili kompletnú elektrícku inštaláciu vrátane realizácie elektrického rozvádzača."+
        "Súčasťou prác na tejto novostavbe bol aj návrh a montáž systému ochrany pred bleskom.",
        numOfPhotos: 13,
        mainImgIndex: 5,
      },
      {
        title: "Rodinný dom v obci Vyšná Voľa",
        subtitle: "Návrh a realizácia bleskozvodu",
        folder: "VysnaVola_Blesk_2/",
        description:
        "Zriadenie zachytávacej a zvodovej sústavy s pripojením na jestvujúci základový uzemňovač.",
        numOfPhotos: 7,
        mainImgIndex: 4,
      },
      {
        title: "Rodinný dom Sabinov",
        subtitle: "Elektroinštalácia a rozvádzač",
        folder: "Sabinov_Elek_2/",
        description:
        "Návrh a realizácia kompletných elektrických rozvodov a elektrického rozvádzača rodinného domu.",
        numOfPhotos: 8,
        mainImgIndex: 4,
      },
      {
        title: "Rodinný dom v obci Sedliská",
        subtitle: "Kompletný fotovoltický system",
        folder: "Sedliska_FV/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne podľa požiadaviek nášho klienta.",
        numOfPhotos: 6,
        mainImgIndex: 5,
      },
      {
        title: "Rodinný dom v obci Drienica",
        subtitle: "Elektroinštalácia a rozvádzač",
        folder: "Drienica_Elek/",
        description:
        "Návrh a realizácia kompletných elektrických rozvodov a elektrického rozvádzača rodinného domu.",
        numOfPhotos: 9,
        mainImgIndex: 9,
      }, 
      {
        title: "Rodinný dom Župčany",
        subtitle: "Kompletný fotovoltický system",
        folder: "Zupcany_FV/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne na rodinnom dome v Župčanoch.",
        numOfPhotos: 9,
        mainImgIndex: 5,
      },
      {
        title: "Rodinný dom Veľká Lesná",
        subtitle: "Kompletný fotovoltický system",
        folder: "VelkaLesna_FV/",
        description:
        "Návrh a realizácia fotovoltickej elektrárne podľa požiadaviek nášho klienta.",
        numOfPhotos: 6,
        mainImgIndex: 4,
      },
      {
        title: "Rodinný dom Veľká Lomnica",
        subtitle: "Návrh a realizácia bleskozvodu",
        folder: "VelkaLomnica_Blesk/",
        description:
        "Zriadenie zachytávacej a zvodovej sústavy s pripojením na jestvujúci základový uzemňovač.",
        numOfPhotos: 8,
        mainImgIndex: 5,
      },
      {
        title: "Rodinný dom Poproč",
        subtitle: "Kompletný fotovoltický system",
        folder: "Poproc_FV_2/",
        description:
        "Po krátkej dobe sme sa opäť dostali do obce Poproč, kde sme nedávno odovzdali fotovoltický systém pre dvoch zákazníkov naraz. " + 
        "Tento krát sme však zriaďovali fotovoltický systém už len pre jeden rodinný dom.",
        numOfPhotos: 7,
        mainImgIndex: 5,
      },
      {
        title: "Rodinný dom Prešov (Cemjata)",
        subtitle: "Elektroinštalácia a rozvádzač",
        folder: "Cemjata_Elek/",
        description:
        "Návrh a realizácia kompletných elektrických rozvodov a elektrického rozvádzača rodinného domu v meste Prešov (mestská časť Cemjata).",
        numOfPhotos: 16,
        mainImgIndex: 16,
      },
      {
        title: "Giraltovce",
        subtitle: "Fotovolticka elektráreň v Giraltovciach",
        folder: "Giraltovce_FV/",
        description:
        "Veľkosť fotovoltickej elektrárne bola navrhnutá na 5,85kwp. 13 panelov 450wp. Invertor HUAWEI SUN 2000-10KTL-M1. " + 
        "Majiteľ uvažoval do budúcna rozšíriť fotovoltickú elektráreň nie len o panely, ale aj o batérie. Preto sme sa po vzájomnej dohode rozhodli použiť tento typ invertora.",
        numOfPhotos: 10,
        mainImgIndex: 8,
      },
      {
        title: "Rodinný dom v Sabinove",
        subtitle: "Elektroinštalácia a rozvádzač",
        folder: "Sabinov_Elek/",
        description:
        "Návrh a realizácia kompletných elektrických rozvodov a elektrického rozvádzača rodinného domu v meste Sabinov.",
        numOfPhotos: 16,
        mainImgIndex: 15,
      },
      {
        title: "Ľubotice",
        subtitle: "Samostatne stojaci fotovoltický systém",
        folder: "Lubotice_FV/",
        description:
        "Návrh a realizácia samostatne stojacej fotovoltickej elektrárne podľa požiadaviek nášho klienta.",
        numOfPhotos: 6,
        mainImgIndex: 2,
      },
      {
        title: "Cukráreň v meste Sabinov",
        subtitle: "Elektroinštalácia, bleskozvod a fotovoltika",
        folder: "Sabinov_Elek-Blesk-FV/",
        description:
        "Pre cukráreň v meste Sabinov sme zriadili systém pre ochranú pred bleskom. Tu sa však naša úloha neskončila a na streche sme pokračovali v práci na fotovoltickom systéme." + 
        "V objekte nasledovala rozsiahlá výmena starých vodičov a obvodov a tiež komplikovaná výmena hlavného rozvádzača budovy.",
        numOfPhotos: 20,
        mainImgIndex: 1,
      },
      {
        title: "Rodinný dom v Tatranskej Lomnici",
        subtitle: "Elektroinštalácia a rozvádzač",
        folder: "Lomnica_Elek/",
        description:
        "V Tatranskej Lomnici sme sa postarali o kompletnú elektrickú inštaláciu a montáž elektrického rozvádzača pre rodinný dom. Okrem elektrických obvodov sme sa tiež postarali aj o datový rozvod, ktorý zahŕňal obvody pre televíziu, internet ale aj kamerový systém.",
        numOfPhotos: 22,
        mainImgIndex: 14,
      },
      {
        title: "Dva rodinné domy v Poproči",
        subtitle: "Kompletný fotovoltický system",
        folder: "Poproc_FV/",
        description:
        "V obci Poproč, neďaleko Košíc, sme zriadili kompletný fotovoltaický systém pre hneď dva rodinné domy.",
        numOfPhotos: 16,
        mainImgIndex: 16,
      },
      {
        title: "Drevenica v obci Obišovce",
        subtitle: "Realizácia bleskozvodu",
        folder: "Obisovce_Blesk/",
        description:
        "Na tejto krásnej drevenici v obci Obišovce sme pracovali na inštalácii bleskozvodu a doplnkového uzemňovacieho pásu. Dali sme si tiež zaležať aj na tom, aby vyhotovenie nenarúšalo krásny vzhľad tejto drevenice.",
        numOfPhotos: 18,
        mainImgIndex: 1,
      },
      {
        title: "Rodinný dom Byšta",
        subtitle: "Realizácia elektroinštalácie a bleskozvodu",
        folder: "Bysta_Blesk-Elek/",
        description:
        "V tomto rodinnom dome sme zabezpečili kompletnú elektrícku inštaláciu, vrátane datových obvodov (televízia a internet). Spolu s elektroinštaláciou sme tiež navrhli a zapojili elektrický rozvádzač."+
        "Okrem prác v interiéri nás tiež čakali práce na streche, kedže súčasťou prác na tejto novostavbe bol aj návrh a montáž systému ochrany pred bleskom.",
        numOfPhotos: 27,
        mainImgIndex: 18,
      },
      {
        title: "Rodinný dom a garáž Drienica",
        subtitle: "Realizácia bleskozvodu",
        folder: "Drienica_Blesk_2/",
        description:
          "Realizácia bleskozvodu na rodinnom dome a samostatne stojacej garáži v obci Drienica.",
        numOfPhotos: 11,
      },
      {
        title: "Dielňa hasičskej techniky",
        subtitle: "Realizácia bleskozvodu a uzemnenia",
        folder: "VysnaVola_Blesk/",
        description:
          "Kompletná realizácia uzemnenia a zachytávacej sústavy s oddialeným bleskozvodom pre dielňu hasičského náradia a techniky",
        numOfPhotos: 8,
      },
      {
        title: "Rodinný dom Miľpoš",
        folder: "Milpos_Blesk/",
        subtitle: "Montáž bleskozvodu",
        description: "Realizácia bleskozvodu na rodinnom dome v obci Miľpoš.",
        numOfPhotos: 7,
      },
      {
        title: "Rodinný dom Fintice",
        subtitle: "Realizácia elektroinštalácie a bleskozvodu",
        folder: "Fintice_Blesk-Elek/",
        description:
          "V tomto dome sme zabezpečili a realízovali montáž bleskozvodu a kompletnú elektrickú inštálaciu, vrátane obvodov pre termostaty. Súčasťou elektroinštalácie bolo samozrejme aj zapojenie elektrického rozvádzača a hlavnej prípojky",
        numOfPhotos: 26,
      },
      {
        title: "Rodinný dom Vyšná Šebastová",
        subtitle: "Montáž bleskozvodu",
        folder: "VysnaSebastova_Blesk/",
        description:
          "Realizácia bleskozvodu na rodinnom dome v obci Vyšná Šebastová.",
        numOfPhotos: 7,
      },
      {
        title: "Bytový dom v Prešove",
        subtitle: "Inštalácia schodiskového a núdzového osvetlenia",
        folder: "Presov_BD_Elek/",
        description:
          "V tomto bytovom dome sme realizovali montáž osvetlenia schodiska vrátane núdzového osvetlenia. Okrem toho sme taktiež zabezpečovali odstranenie pôvodných a následnú inštaláciu nových svetelných elktrických obvodoch v pivničných priestoroch tohto bytového domu.",
        numOfPhotos: 8,
      },
      {
        title: "Rodinný dom Rozhanovce",
        subtitle: "Montáž bleskozvodu",
        folder: "Rozhanovce_Blesk/",
        description:
          "Realizácia bleskozvodu na rodinnom dome v obci Rozhanovce.",
        numOfPhotos: 11,
      },
      {
        title: "Rodinný dom Župčany",
        subtitle: "Inštalácia infra fólií",
        folder: "Zupcany_IF/",
        description:
          "Inštalácia infra fólií ako primárneho kúrenia v rodinom dome v Župčanoch.",
        numOfPhotos: 14,
      },
      {
        title: "Bytový dom Sabinov",
        subtitle: "Montáž bleskozvodu",
        folder: "Sabinov_BD_Blesk/",
        description: "Realizácia bleskozvodu bytového domu v Sabinove.",
        numOfPhotos: 8,
        mainImgIndex: 6,
      },
      {
        title: "Rodinný dom Drienica",
        subtitle: "Montáž bleskozvodu",
        folder: "Drienica_Blesk/",
        description:
          "Realizácia bleskozvodu na rodinnom dome v obci Drienica.",
        numOfPhotos: 8,
      },
    ],
  },
  getters:{
    getProjects: (state) =>{
        return state.projects
    },
  },
};

export default projects