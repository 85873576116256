<template>
  <v-container class="mt-12 pa-0 footer" fluid>
    <v-container class="px-0 py-7">
      <v-row justify="center" align="center">
        <!-- LOGO -->
        <v-col
          cols="12"
          sm="4"
          class="py-0 d-flex flex-column align-center mr-6"
        >
          <VLogoHorizontal color="white" />
        </v-col>
        <!-- KONTAKTNÉ ÚDAJE -->
        <v-col cols="12" sm="4" class="py-0 pt-4">
          <v-card-title
            :class="'unselectable primary--text justify-' + justify"
          >
            Kontaktné údaje:
          </v-card-title>
          <v-row class="ma-0 d-flex flex-column" :align="justify">
            <!-- Phone number -->
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="py-2"
                  v-bind="attrs"
                  v-on="on"
                  v-clipboard:copy="companyPhone"
                  color="primary"
                  text
                >
                  <v-icon class="mr-2" color="primary">
                    mdi-phone-in-talk
                  </v-icon>
                  <p class="ma-0 white--text">
                    {{ companyPhone }}
                  </p>
                </v-btn>
              </template>
              <span>Skopírovať číslo</span>
            </v-tooltip>
            <!-- Email -->
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="py-2 noUpperCase"
                  v-bind="attrs"
                  v-on="on"
                  v-clipboard:copy="companyEmail"
                  color="primary"
                  text
                >
                  <v-icon class="mr-2" color="primary">
                    mdi-email-outline
                  </v-icon>
                  <p class="ma-0 white--text">
                    {{ companyEmail }}
                  </p>
                </v-btn>
              </template>
              <span>Skopírovať email</span>
            </v-tooltip>
            <!-- Facebook -->
            <v-row
              :class="'ma-0 mt-2 ' + (isMobileDevice ? '' : 'ml-12')"
              align="center"
              justify="center"
            >
              <v-btn
                href="https://www.facebook.com/profile.php?id=100075464307482"
                target="_blank"
                height="30"
                width="30"
                fab
                text
              >
                <v-icon class="facebook-btn" size="30"> mdi-facebook </v-icon>
              </v-btn>
              <v-btn
                class="ml-4"
                href="https://www.instagram.com/boltline.sk/"
                target="_blank"
                height="34"
                width="34"
                fab
                text
              >
                <v-icon class="instagram-btn" size="35"> mdi-instagram </v-icon>
              </v-btn>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="white"></v-divider>
    <v-row class="ma-0 text-center">
      <v-card-text class="unselectable white--text">
        Copyright © 2022 {{ companyName }}
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
import VLogoHorizontal from "./VLogoHorizontal.vue";
export default {
  name: "TheFooter",
  components: { VLogoHorizontal },
  data() {
    return {
      navItems: [
        ["Domov", "/"],
        ["Služby", "/sluzby"],
        ["Projekty", "/projekty"],
      ],
      companyName: "BoltLine s.r.o.",
      companyEmail: "info@boltline.sk",
      companyPhone: "+421 917 380 165",
    };
  },
  computed: {
    isMobileDevice() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    justify() {
      return this.$vuetify.breakpoint.xsOnly ? "center" : "start";
    },
  },
};
</script>
<style lang="scss" scoped>
.noUpperCase {
  text-transform: none;
}
.facebook-btn {
  color: #197be3 !important;
}
.instagram-btn {
  color: var(--v-footer-base) !important;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
</style>
