<template>
  <v-overlay class="img-opener" v-if="selectedImage" :opacity="0.8" z-index="2">
    <v-container class="img-container" fill-height>
      <v-img
        class="rounded big-img"
        :style="imageBottomMargin"
        :src="getImageUrl(selectedImage)"
        v-touch="{ left: () => swipe('Left'), right: () => swipe('Right') }"
        max-width="90vw"
        :height="imageHeight"
        v-click-outside="{
          handler: closeImageOpener,
          include: getCloseIncludedElements,
        }"
        transition="scale-transition"
        :key="openedImageKey"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>
    <div class="footer-container">
      <!-- Actions -->
      <div class="footer-actions">
        <v-btn
          class="included"
          @click="previousImage"
          color="primary"
          x-small
          fab
        >
          <v-icon size="30">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          class="included mx-8"
          color="primary"
          @click="closeImageOpener"
          fab
          small
        >
          <v-icon size="30"> mdi-window-close </v-icon>
        </v-btn>
        <v-btn class="included" @click="nextImage" color="primary" x-small fab>
          <v-icon size="30"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <div class="footer-showcase included">
        <!-- Image horizontal showcase -->
        <div v-if="showControls">
          <vue-horizontal ref="img-scroller" style="max-width: 90vw">
            <VImage
              class="included"
              v-for="number in projectData.numOfPhotos"
              :key="number"
              :src="getImageUrl(number)"
              @img-click="showImage(projectData, number)"
              :width="40"
              :height="60"
              :overlay="selectedImage !== number"
              overlayOpacity="0.6"
            />
            <template v-slot:btn-prev>
              <v-btn fab x-small color="background">
                <v-icon color="footer">mdi-transfer-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:btn-next>
              <v-btn fab x-small color="background">
                <v-icon color="footer">mdi-transfer-right</v-icon>
              </v-btn>
            </template>
          </vue-horizontal>
        </div>
        <!-- Show/Hide controls -->
        <v-btn @click="showControls = !showControls" color="background" x-small>
          <v-icon color="footer">
            {{
              showControls ? "mdi-chevron-double-down" : "mdi-chevron-double-up"
            }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-overlay>
</template>

<script>
// FEAT Zooming functionality for image opener
import VueHorizontal from "vue-horizontal";
import VImage from "./VImage.vue";

export default {
  name: "VImageOpener",
  components: { VImage, VueHorizontal },
  data() {
    return {
      projectData: {},
      selectedImage: null,
      openedImageKey: 0,
      showControls: true,
    };
  },
  computed: {
    imageBottomMargin() {
      return this.showControls ? "margin-bottom: 140px" : "margin-bottom: 72px";
    },
    imageHeight() {
      return this.showControls ? "70vh" : "80vh";
    },
  },
  watch: {
    selectedImage() {
      if (this.selectedImage) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
  },
  methods: {
    swipe(direction) {
      if (direction === "Left") {
        this.nextImage();
      } else if (direction === "Right") {
        this.previousImage();
      }
    },
    previousImage() {
      if (this.selectedImage > 1) {
        this.showImage(this.projectData, this.selectedImage - 1);
      }
    },
    nextImage() {
      if (this.selectedImage < this.projectData.numOfPhotos) {
        this.showImage(this.projectData, this.selectedImage + 1);
      }
    },
    showImage(projectData, imgIndex) {
      this.projectData = projectData;
      this.selectedImage = imgIndex;
      this.openedImageKey++;

      // FEAT auto-scroll showcase, when actual is out of view
      // setTimeout(() => {
      //   this.$refs["img-scroller"].scrollToIndex(imgIndex - 1);
      // }, 100);
    },
    closeImageOpener() {
      this.selectedImage = null;
      this.projectData = {};
      this.openedImageKey = 0;
    },
    getCloseIncludedElements() {
      let elements = document.querySelectorAll(".included");
      let array = [];
      elements.forEach((element) => {
        array.push(element);
      });
      return array;
    },
    getImageUrl(imgNumber) {
      return require("../../public/projects/" +
        this.projectData.folder +
        imgNumber +
        ".jpg");
    },
  },
  mounted() {
    document.documentElement.style.overflow = "auto";
  },
  updated() {
    if (this.selectedImage) {
      document.documentElement.style.overflow = "hidden";
      return;
    }
    document.documentElement.style.overflow = "auto";
  },
  destroyed() {
    document.documentElement.style.overflow = "auto";
  },
};
</script>

<style lang="scss">
.img-opener .v-overlay__content {
  height: 100% !important;
  width: 100% !important;
}
.img-container {
  display: flex;
  justify-content: center;
}
.footer-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-actions {
  margin-bottom: 12px;
}
.footer-showcase {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
</style>
