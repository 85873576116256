import Vue from 'vue'
import VueRouter from 'vue-router'

// Components
import HomePage from '../components/HomePage'
import ProjectsPage from '../components/ProjectsPage'
import ContactsPage from '../components/ContactsPage'
import ServicesPageFotovltaic from '../components/Services/PageFotovltaic'
import ServicesPageLightning from '../components/Services/PageLightning'
import ServicesPageProject from '../components/Services/PageProject'
import ServicesPageElectric from '../components/Services/PageElectric'
import ServicesPageRecuperation from '../components/Services/PageRecuperation'
import ServicesPageHeating from '../components/Services/PageHeating'

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/sluzby/fotovoltika',
        name: 'PageFotovltaic',
        component: ServicesPageFotovltaic,
    },
    {
        path: '/sluzby/bleskozvod',
        name: 'PageLightning',
        component: ServicesPageLightning,
    },
    {
        path: '/sluzby/projekt',
        name: 'PageProject',
        component: ServicesPageProject,
    },
    {
        path: '/sluzby/elektroinstalacia',
        name: 'PageElectric',
        component: ServicesPageElectric,
    },
    {
        path: '/sluzby/rekuperacia',
        name: 'PageRecuperation',
        component: ServicesPageRecuperation,
    },
    {
        path: '/sluzby/kurenie',
        name: 'PageHeating',
        component: ServicesPageHeating,
    },
    {
        path: '/projekty',
        name: 'ProjectsPage',
        component: ProjectsPage,
    },
    {
        path: '/kontakt',
        name: 'ContactsPage',
        component: ContactsPage,
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router