<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        src="../../../public/backgrounds/services/heating_bg.jpg"
        lazy-src="../../../public/backgrounds/services/heating_bg-lazy.jpg"
        transition="fade-transition"
        :height="400"
        alt="Pozadie-sluzby"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-row>
        </template>
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container class="ma-0 px-4 text-center">
            <p class="site-text__h3 white--text">Elektrické kúrenie</p>
            <p class="ml-1 italic" style="max-width: 800px">
              Vyhrievajte vaše domácnosti či firmy vďaka moderným elektrickým
              zariadeniam pohodlne a bez starostí.
            </p>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row
      :class="'ma-0 ' + $vuetify.breakpoint.xsOnly ? '' : 'my-8'"
      justify="center"
    >
      <!-- Vykurovacie infra-folie -->
      <ContentSection
        title="Infra-folie"
        img="kurenie/vykurovacie_folie"
        id="infra-folie"
      >
        <template v-slot:text>
          Toto priame elektrické vykurovanie sa teší veľkej obľube najmä v
          prípade novostavieb. Fólia sa umiestňuje priamo pod podlahu spolu s
          izolačnými vrstvami, preto nie sú nutné žiadne stavebné úpravy. Nutná
          je však príprava vodičov a termostatov pre napojenie fólii, ktorú Vám
          zabezpečíme už pri realizácii elektrických rozvodoch.
        </template>
      </ContentSection>
      <!-- Vykurovacie rohoze -->
      <ContentSection
        title="Vykurovacie rohože"
        img="kurenie/vykurovacie_rohoze"
        id="vykurovacie-rohoze"
        inverted
      >
        <template v-slot:text>
          Tieto rohože sa umiestňujú do poterovej vrstvy podlahy. Sú to rohože
          vytvorené z paralelne zapojených odporových drôtov, ktoré sú vysoko
          odolné voči mechanickému namáhaniu. Paralelné zapojenie odporových
          drôtov zabezpečuje, že pri výpadku jednej časti nedôjde k vypnutiu
          celej vetvy.
        </template>
      </ContentSection>
      <!-- Infra ziarice -->
      <ContentSection
        title="Infra-žiariče"
        img="kurenie/infra_ziarice"
        id="infra-ziarice"
      >
        <template v-slot:text>
          Ak nie je možné umiestniť kúrenie do podlahy, je tu možnosť inštalovať
          ho do stropu v podobe infračervených žiaričov. Sú to tenké biele
          panely umiestnené na strope alebo na stene miestnosti. V prípade
          stenových panelov je dokonca možné si zakúpiť panel, ktorý vyzerá ako
          obraz.
        </template>
      </ContentSection>
    </v-row>
  </v-container>
</template>

<script>
import ContentSection from "./ContentSection.vue";
export default {
  name: "PageHeating",
  components: { ContentSection },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        setTimeout(() => {
          document.getElementById(refName).scrollIntoView(true);
        }, 100);
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  },
};
</script>