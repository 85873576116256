<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="d-flex flex-column justify-center"
      :width="mobileDevice ? '45vw' : '30vw'"
      :height="mobileDevice ? 300 : 250"
      rounded="lg"
      :elevation="hover ? 16 : 6"
      :to="{
        name: 'ProjectsPage',
        hash: '#' + folder,
      }"
    >
      <v-img
        class="rounded-lg"
        :src="
          require('../../public/projects/' + folder + mainImgIndex + '.jpg')
        "
        :lazy-src="
          require('../../public/projects/' + folder + mainImgIndex + '.jpg')
        "
        max-height="140"
        :alt="title"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-row class="ma-0 mx-2 text-center flex-column" justify="center">
        <p class="ma-0 site-text__h6">
          {{ title }}
        </p>
        <p class="ma-0 site-text" style="font-size: 0.9rem">
          {{ subtitle }}
        </p>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "HomePageProjectTile",
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false },
    folder: { type: String, required: true },
    mainImgIndex: { type: Number, required: false, default: 1 },
  },
  computed: {
    mobileDevice() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>