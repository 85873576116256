<template>
  <v-img
    class="img rounded"
    @click="handleImageClick"
    :src="src"
    :lazy-src="src"
    :max-width="width"
    :width="width"
    :height="height"
  >
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-row>
    </template>
    <v-overlay v-if="overlay" absolute z-index="1" :opacity="overlayOpacity">
      <slot name="img-overlay"></slot>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: "VImage",
  props: {
    src: { type: String, required: true },
    width: { type: Number, required: false, default: 120 },
    height: { type: Number, required: false, default: 80 },
    overlay: { type: Boolean, required: false, default: false },
    overlayOpacity: { type: String, required: false, default: "0.7" },
  },
  methods: {
    handleImageClick() {
      this.$emit("img-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  cursor: pointer;
  margin: 4px;
}
</style>